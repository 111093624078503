

export function setDeepValue(obj, value, path) {
    if (typeof path === "string") {
        var path = path.split('.');
    }
  
    if(path.length > 1){
        var p=path.shift();
        if(obj[p]==null || typeof obj[p]!== 'object'){
             obj[p] = {};
        }
        setDeepValue(obj[p], value, path);
    }else{
        obj[path[0]] = value;
    }
}

export function getDeepValue(obj, path){
    let val = path.split('.').reduce(function(o, key) {
        if(!o[key]){return ''}
        return o[key];
      }, obj);
    
    // if(val.length > 100){
    //     val = `${val.substring(0, 100)}...`;
    // }
    return val || '';
}


export function Capitalize(char) {
    char = char.toLowerCase();
    return char.charAt(0).toUpperCase() + char.slice(1);
}

export function subString(str,end){
    let _str = str.substring(0,end);
    if(str.length > end){
        _str = `${_str}...`
    }
    return _str
}

export function formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return match[1] + "-" + match[2] + "-" + match[3];
    }
    return phoneNumberString;
  }

export function getStatus(status) {
    console.log({ status: status})
    const mainStyle =
      "inline-flex items-center rounded-full px-7 py-1 text-lg text-white";
      const st = {
        approved:{
          name: "Approved",
          style: mainStyle + " bg-blue-600",
        },
        withdrawn:{
          name: "Withdrawn",
          style: mainStyle + " bg-red-500",
        },
        current: {
          name: "Current",
          style: mainStyle + " bg-green-600",
        },
        cancelled:{
          name: "Cancelled",
          style: mainStyle + " bg-red-600",
        },
        Cancelled:{
          name: "Cancelled",
          style: mainStyle + " bg-brown-600",
        },
        paidup: {
          name: "Paid Up",
          style: mainStyle + " bg-lime-900",
        },
        refunded: {
          name: "Refunded",
          style: mainStyle + " bg-orange-400",
        },
        writtenOff: {
          name: "written Off",
          style: mainStyle + " bg-red-400",
        },
        pendingApproval: {
          name: "Pending Approval",
          style: mainStyle + " bg-green-400",
        },
        paidUp: {
          name: "Paid Up",
          style: mainStyle + " bg-green-900",
        },
        paidOff: {
          name: "Paid Off",
          style: mainStyle + " bg-blue-900",
        },
        new: {
          name: "New",
          style: mainStyle + " bg-green-800",
        },
        late: {
          name: "Late",
          style: mainStyle + " bg-red-800",
        },
          closed: {
              name: "Closed",
              style: mainStyle + " bg-red-800",
          },
        withdrawn: {
          name: "withdrawn",
          style: mainStyle + " bg-indigo-600",
        },
        scheduled: {
          name: "Scheduled",
          style: "inline-flex items-center text-sm text-gray-500",
        },
      };
    return st[status]
      ? st[status]
      : {
          name: "None",
          style: "inline-flex items-center text-sm text-gray-500",
        };
}

export function FormatNumber(str) {
  return str.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function nullTransform(val) {
  if (val === null || val === undefined) {
    return "";
  }
  return val;
}

export function delay (delayInms) {
  return new Promise((resolve) => setTimeout(resolve, delayInms));
};

export function sortListAlphabeticallyByKey(list, key) {
  return list.sort((a, b) => {
    return a[key].localeCompare(b[key]);
  });
}

export function capitalizeFirstLetter(text) {
    if (!text) return text;
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}

export function camelCaseToWords(camelCaseStr) {
    // Use a regular expression to split the string at uppercase letters
    const words = camelCaseStr.split(/(?=[A-Z])/);

    // Capitalize the first letter of each word and join them with a space
    const formattedStr = words
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");

    return formattedStr;
}

export function getImageTypeFromBase64(base64String) {
  const mimeMatch = base64String.match(/^data:([^;]+);base64,/);
  if (mimeMatch && mimeMatch[1]) {
    return mimeMatch[1];
  } else {
    return null; // Or throw an error, depending on your use case
  }
}

export function base64ToBlob(base64String,type) {
  console.log({base64String,type})
  const byteString = atob(base64String);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const intArray = new Uint8Array(arrayBuffer);

  for (let i = 0; i < byteString.length; i++) {
    intArray[i] = byteString.charCodeAt(i);
  }

  const blob = new Blob([intArray], { type: type }); // Adjust the mime type as needed
  return blob;
}
