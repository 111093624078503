'use client'

import { useEffect, useState } from 'react'
import { Dialog,  Transition } from '@headlessui/react'
import {
  Bars3Icon,
  CalendarIcon,
  CogIcon,
  HomeIcon,
  MagnifyingGlassCircleIcon,
  MapIcon,
  MegaphoneIcon,
  SquaresPlusIcon,
  UserGroupIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronLeftIcon, EnvelopeIcon, FunnelIcon, MagnifyingGlassIcon, PhoneIcon } from '@heroicons/react/20/solid'
import Info from '../../../components/dashboard/settings/info'
import Teams from '../../../components/dashboard/settings/teams'
import Signature from '../../../components/dashboard/settings/signature'
import Identicon from "react-identicons";

const user = {
  name: 'Tom Cook',
  imageUrl:
    'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
}

const profile = {
  name: 'Ricardo Cooper',
  imageUrl:
    'https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
  coverImageUrl:
    'https://images.pexels.com/photos/2116721/pexels-photo-2116721.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
  about: `
    <p>Tincidunt quam neque in cursus viverra orci, dapibus nec tristique. Nullam ut sit dolor consectetur urna, dui cras nec sed. Cursus risus congue arcu aenean posuere aliquam.</p>
    <p>Et vivamus lorem pulvinar nascetur non. Pulvinar a sed platea rhoncus ac mauris amet. Urna, sem pretium sit pretium urna, senectus vitae. Scelerisque fermentum, cursus felis dui suspendisse velit pharetra. Augue et duis cursus maecenas eget quam lectus. Accumsan vitae nascetur pharetra rhoncus praesent dictum risus suspendisse.</p>
  `,
  fields: {
    Phone: '(555) 123-4567',
    Email: 'ricardocooper@example.com',
    Title: 'Senior Front-End Developer',
    Team: 'Product Development',
    Location: 'San Francisco',
    Sits: 'Oasis, 4th floor',
    Salary: '$145,000',
    Birthday: 'June 8, 1990',
  },
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [organization, setOrganization] = useState({})

  const [tabs,setTabs] = useState([
    { name: 'Info', href: '#', current: true },
    { name: 'Signature', href: '#', current: false },
    { name: 'Agents', href: '#', current: false },
  ])
  useEffect(()=>{
    try {
        setOrganization(JSON.parse(localStorage.getItem('organization')))
    } catch (error) {
        console.log(error)
    }
  },[setOrganization])

  const clickTab = (index) => {
    let newTabs = [...tabs]
    newTabs.forEach((tab, i) => {
      if (i === index) {
        tab.current = true
      } else {
        tab.current = false
      }
    })
    setTabs(newTabs)
  }
  return (
    <>
    <div className="flex justify-center ">
      <div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0 w-10/12">
        <div className="shadow sm:overflow-hidden sm:rounded-md">
          <div className="space-y-6 bg-white px-4 py-6 sm:p-6">
          <div className="flex h-full">
        <Dialog open={sidebarOpen} onClose={setSidebarOpen} className="relative z-40 lg:hidden">
          <Dialog.Backdrop
            transition
            className="fixed inset-0 bg-gray-600/75 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
          />

          <div className="fixed inset-0 z-40 flex">
            <Dialog.Panel
              transition
              className="relative flex w-full max-w-xs flex-1 transform flex-col bg-white transition duration-300 ease-in-out focus:outline-none data-[closed]:-translate-x-full"
            >
              <Transition.ChildChild>
                <div className="absolute right-0 top-0 -mr-12 pt-2 duration-300 ease-in-out data-[closed]:opacity-0">
                  <button
                    type="button"
                    onClick={() => setSidebarOpen(false)}
                    className="relative ml-1 flex size-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  >
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon aria-hidden="true" className="size-6 text-white" />
                  </button>
                </div>
              </Transition.ChildChild>
              <div className="flex shrink-0 border-t border-gray-200 p-4">
                <a href="#" className="group block shrink-0">
                  <div className="flex items-center">
                    <div>
                      <img alt="" src={user.imageUrl} className="inline-block size-10 rounded-full" />
                    </div>
                    <div className="ml-3">
                      <p className="text-base font-medium text-gray-700 group-hover:text-gray-900">{organization.name}</p>
                      {/* <p className="text-sm font-medium text-gray-500 group-hover:text-gray-700">View profile</p> */}
                    </div>
                  </div>
                </a>
              </div>
            </Dialog.Panel>
            <div aria-hidden="true" className="w-14 shrink-0">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>

        <div className="flex min-w-0 flex-1 flex-col overflow-hidden">
          <div className="lg:hidden">
            <div className="flex items-center justify-between border-b border-gray-200 bg-gray-50 px-4 py-1.5">
              <div>
                <img
                  alt="Your Company"
                  src="https://tailwindui.com/plus/img/logos/mark.svg?color=pink&shade=500"
                  className="h-8 w-auto"
                />
              </div>
              <div>
                <button
                  type="button"
                  onClick={() => setSidebarOpen(true)}
                  className="-mr-3 inline-flex size-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-pink-600"
                >
                  <span className="sr-only">Open sidebar</span>
                  <Bars3Icon aria-hidden="true" className="size-6" />
                </button>
              </div>
            </div>
          </div>
          <div className="relative z-0 flex flex-1 overflow-hidden">
            <main className="relative z-0 flex-1 overflow-y-auto focus:outline-none xl:order-last">
              {/* Breadcrumb */}
              <nav aria-label="Breadcrumb" className="flex items-start px-4 py-3 sm:px-6 lg:px-8 xl:hidden">
                <a href="#" className="inline-flex items-center space-x-3 text-sm font-medium text-gray-900">
                  <ChevronLeftIcon aria-hidden="true" className="-ml-2 size-5 text-gray-400" />
                  <span>Directory</span>
                </a>
              </nav>

              <article>
                {/* Profile header */}
                <div>
                  <div>
                    <img alt="" src={profile.coverImageUrl} className="h-32 w-full object-cover lg:h-48" />
                  </div>
                  <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
                    <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
                      <div className="flex">
                        {/* <img
                          alt=""
                          src={profile.imageUrl}
                          className="size-24 rounded-full ring-4 ring-white sm:size-32"
                        /> */}
                        <Identicon
                              bg={"white"}
                              size="40"
                              className="size-24 rounded-full ring-4 ring-white sm:size-32"
                              string={organization._id}
                            />
                      </div>
                      <div className="mt-6 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
                        <div className="mt-6 min-w-0 flex-1 sm:hidden 2xl:block">
                          <h1 className="truncate text-2xl font-bold text-gray-900">{organization.name}</h1>
                        </div>
                        {/* <div className="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-x-4 sm:space-y-0">
                          <button
                            type="button"
                            className="inline-flex justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          >
                            <EnvelopeIcon aria-hidden="true" className="-ml-0.5 size-5 text-gray-400" />
                            Message
                          </button>
                          <button
                            type="button"
                            className="inline-flex justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          >
                            <PhoneIcon aria-hidden="true" className="-ml-0.5 size-5 text-gray-400" />
                            Call
                          </button>
                        </div> */}
                      </div>
                    </div>
                    <div className="mt-6 hidden min-w-0 flex-1 sm:block 2xl:hidden">
                      <h1 className="truncate text-2xl font-bold text-gray-900">{organization.name}</h1>
                    </div>
                  </div>
                </div>

                {/* Tabs */}
                <div className="mt-6 sm:mt-2 2xl:mt-5">
                  <div className="border-b border-gray-200">
                    <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
                      <nav aria-label="Tabs" className="-mb-px flex space-x-8">
                        {tabs.map((tab,index) => (
                          <a
                            key={tab.name}
                            href={tab.href}
                            aria-current={tab.current ? 'page' : undefined}
                            onClick={() => clickTab(index)}
                            className={classNames(
                              tab.current
                                ? 'border-pink-500 text-gray-900'
                                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                              'whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium',
                            )}
                          >
                            {tab.name}
                          </a>
                        ))}
                      </nav>
                    </div>
                  </div>
                </div>

                {/* Description list */}
                {tabs[0].current && <Info organization={organization} />}

                {tabs[1].current && <Signature  organization={organization} setOrganization={setOrganization}/>}

                {/* Team member list */}
                {tabs[2].current && <Teams team={[]} />}
              </article>
            </main>
          </div>
        </div>
      </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}
